(function($) {

//******************************************************************************
//* Trigger "Resize ready" event
//******************************************************************************
nd_resize_handling = {
    resize_timer: null,
    start_width: null,
    start_height: null,

    init: function () {
        this._set_start_values_to_current_window();

        $( window ).resize(function() {

            clearTimeout(nd_resize_handling.resize_timer);
            nd_resize_handling.resize_timer = setTimeout(function() {
                $( window ).trigger( "nd_resize_ready" );

                // Trigger horizontal resize event if current and start window width not same
                if (nd_resize_handling.start_width !== $(window).width()) {
                    $( window ).trigger( "nd_resize_horizontal_ready" );
                }

                // Trigger vertical resize event if current and start window height not same
                if (nd_resize_handling.start_height !== $(window).height()) {
                    $( window ).trigger( "nd_resize_vertical_ready" );
                }
                
                // Save start values to current window
                nd_resize_handling._set_start_values_to_current_window();
            }, 200);
        });
    } ,

    // Save current window height and width to variables
    _set_start_values_to_current_window: function () {
        nd_resize_handling.start_width = $(window).width();
        nd_resize_handling.start_height = $(window).height();
    }
};



//******************************************************************************
//* Trigger "Scroll thinned out" event
//******************************************************************************
nd_scroll_handling = {
    scroll_timer: null,
    scroll_ready_timer: 0,
    scroll_counter: 0,

    init: function () {
    	nd_scroll_handling._init_scroll_counter();
    	nd_scroll_handling._init_scroll_timer();
        nd_scroll_handling._init_scroll_ready();
    },

   _init_scroll_timer: function () {
   		$( window ).bind('scroll' , function () {
            clearTimeout(nd_scroll_handling.scroll_timer);
            nd_scroll_handling.scroll_timer = setTimeout(function() {
                $( window ).trigger( "nd_scroll_thinned_out" );
            }, 50);
        });
   	},

    _init_scroll_counter: function () {
        $( window ).bind('scroll' , function () {
            if (nd_scroll_handling.scroll_counter === 5) {
                $( window ).trigger( "nd_scroll_thinned_out" );
                nd_scroll_handling.scroll_counter = 0;
            } else {
                nd_scroll_handling.scroll_counter++;
            }
        }); 
    },

    _init_scroll_ready: function () {
        $( window ).bind('scroll' , function () {
            clearTimeout(nd_scroll_handling.scroll_ready_timer);
            nd_scroll_handling.scroll_ready_timer = setTimeout(function() {
                $( window ).trigger( "nd_scroll_ready" );
            }, 200);
        });
    }
};

})(jQuery); 