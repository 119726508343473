(function($) {

//******************************************************************************
//* Same height groups
//******************************************************************************
nd_same_height_groups = {
    cache: {},

    init: function () {
        
        // Refresh height
        var calculate_height = setTimeout(function() {
            nd_same_height_groups.refresh();
        }, 500);

        $(window).on('nd_resize_ready', function() {
            nd_same_height_groups.refresh();
        });
    },

    refresh: function () {
        this.cache = {};
        var max_height = 0;
        var screen_size = 0;

        // Disable height
        $("[data-same-height-group]").css("height", "");

        
            $("[data-same-height-group]").each(function () {

                if ($(this).attr('data-same-height-group-always')) {
                    screen_size = 0;
                } else if ($(this).attr('data-same-height-group-min-xxs')) {
                    screen_size = BREAKPOINT_SCREEN_XXS_MAX;
                } else if ($(this).attr('data-same-height-group-min-sm')) {
                    screen_size = SCREEN_SIZE_SM_MAX;
                } else {
                    screen_size = SCREEN_SIZE_XS_MAX;
                }
                

                // Set equal height            
                if (window.innerWidth > screen_size) {

                    // Get max height
                    max_height = nd_same_height_groups._get_max_height($(this).attr("data-same-height-group"));

                    // Set height
                    $(this).css("height", max_height);   
                }           
            });
        
        
    },

    _get_max_height: function (group) {

        // Return cached, if max height already exist in cache var
        if (this.cache[group] !== undefined) {
            return this.cache[group];
        }

        // Calculate max height of current group
        max_height = 0;
        $("[data-same-height-group='" + group +  "']").each(function () {
            if ($(this).outerHeight() > max_height) {
                max_height = $(this).outerHeight();
            }

        });

        this.cache[group] = max_height;

        return max_height;
    }


};

})(jQuery); 