(function($) {

    //******************************************************************************
    //* UAParser init
    //******************************************************************************
    uaparser = new UAParser();



    //******************************************************************************
    //* Browser and os detection
    //******************************************************************************
    nd_browser_and_os_detection = {
        init: function () {
            this._detect_ie();
            this._detect_ios();
        },

        _detect_ie: function () {
            if (uaparser.getBrowser().name === "IE") {
                $('body').addClass('pwt_is_ie');
            } else {
                $('body').addClass('pwt_is_not_ie');            
            }
            if (uaparser.getBrowser().name === "Edge") {
                $('body').addClass('pwt_is_edge');
            } else {
                $('body').addClass('pwt_is_not_edge');            
            }
        },

        _detect_ios: function () {
            if (uaparser.getOS().name === "iOS") {
                $('body').addClass('pwt_is_ios');
            }
            else {
                $('body').addClass('pwt_is_not_ios');            
            }
        }    
    };



    //******************************************************************************
    //* Overlay Interface
    //******************************************************************************
    nd_overlay_interface = {
        current_hide_timeout: 0,

        init: function () {
            $('#scroll_up').on('click', function () {
                nd_overlay_interface._scrollToTop(); 
            });

            $(document).on('scroll', function() {
                nd_overlay_interface._refreshOverlayVisibility(); 
            });
        }, 

        _scrollToTop: function () {
            $.smoothScroll({
                scrollTarget: '#'
            });
        },

        _refreshOverlayVisibility: function() {
            var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if ( bodyScrollTop > 100) {
                $('#scroll_up').addClass('visible');
                clearTimeout(nd_overlay_interface.current_hide_timeout);
                nd_overlay_interface.current_hide_timeout = setTimeout(function () { 
                    $('#scroll_up').removeClass('visible');
                }, 3000);
            }
            else {
                $('#scroll_up').removeClass('visible');
            }
        },
    };

})(jQuery); 