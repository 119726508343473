(function($) {

//******************************************************************************
//* Navigation desktop
//******************************************************************************
pwt_nav_desktop = {
    scroll_top_start: undefined,
    nav_overlay_default_min_height: 300, 
    
    init: function () {
        this._init_events();
        this._mark_menu_item_as_active();
    },

    _init_events: function () {
        // Hide overlay if user start scrolling
        $(window).on('nd_scroll_thinned_out', function() {

            // Save current scroll value when start scrolling
            if (pwt_nav_desktop.scroll_top_start === undefined) {
                pwt_nav_desktop.scroll_top_start = $(document).scrollTop();
            }

            if (Math.abs($(document).scrollTop() - pwt_nav_desktop.scroll_top_start) > (window.innerHeight / 2)) {
                pwt_nav_desktop._hide_overlay();
            }
        });

        // Refresh overlay height on screen resize
        $(window).on('nd_resize_ready', function() {
            if ($("li[data-level='0'].selected").length > 0) {
                pwt_nav_desktop._refresh_overlay_height($("li[data-level='0'].selected"));
            }
        });

        $(window).on('nd_scroll_ready', function() {
            pwt_nav_desktop.scroll_top_start = undefined;
        });

        $("li[data-level='0'][data-has-sub='true']").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();

            // Get status and id of clicked element
            var cur = $(this);
            var is_selected = cur.hasClass("selected");
            var item_id = cur.attr("data-id");

            // Reset current status
            $("li[data-level='0']").removeClass("selected");
            $("[data-level='1'] .element").removeClass("selected");
            $("[data-level='1']").removeClass("visible");

            pwt_nav_desktop._refresh_overlay_height(cur);

            if (!is_selected) {   
                cur.addClass("selected");
                $("[data-level='1'][data-parent-id='" + item_id.toString() + "']").addClass("visible");
            }
            pwt_nav_desktop._refresh_overlay_visibility();
        });
        
         // Hide overlay on body click
        $("body").click(function (event) {
            // Hide overlay only if click is not on overlay, or children
            if ( $(event.target).closest('[data-section="nav_overlay"]').length ===  0) {
                pwt_nav_desktop._hide_overlay();
            }
        });
    },

    _refresh_overlay_visibility: function () {
        if ($("li[data-level='0'].selected").length > 0) {
            $("[data-section='nav_overlay']").addClass("visible");
            $("#desktop_nav > ul").addClass('has_opened_sub');
        }
        else {
            $("[data-section='nav_overlay']").removeClass("visible");
            $("#desktop_nav > ul").removeClass('has_opened_sub');
        }
    },

    _refresh_overlay_height: function ( $element ) {
        var default_height = pwt_nav_desktop.nav_overlay_default_min_height;
        var level0_id = $element.attr('data-id');
        var height = 0;

        $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css("min-height", 0);

        height = $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').outerHeight(true);

        // Gather level1 ids
        $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').find('.element').each( function () {
            var level1_id = $(this).attr('data-menuitem-id');
            var current_height = $(this).outerHeight();
            if (current_height > height) {
                height = current_height;
            }
        });
        
        if (height > default_height) {
            $('[data-section="nav_overlay"]').css('min-height', height);

            // Minus 48 pixels for height adjustment based on the data-level-1 margins (24 + 24)
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css("min-height", height - 48);
        } else {
            $('[data-section="nav_overlay"]').css('min-height', default_height);

            // Minus 48 pixels for height adjustment based on the data-level-1 margins (24 + 24)
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').css("min-height", default_height - 48);
        }
    },

    _hide_overlay: function () {
        $("li[data-level='0'].selected").removeClass("selected");
        pwt_nav_desktop._refresh_overlay_visibility();
    },

    // Activate menu item on detail pages of custom post types
    _mark_menu_item_as_active: function() {
        if (php_to_js.pwt_detail_page_post_type !== undefined) {
            var current_post_type = php_to_js.pwt_detail_page_post_type;

            if (php_to_js.menu_item_to_activate !== null) {
                if (php_to_js.menu_item_to_activate.hasOwnProperty(current_post_type)) {
                    $('.'+php_to_js.menu_item_to_activate[current_post_type]).addClass('current-menu-parent');
                }
            }
        }
    }
};



//******************************************************************************
//* Navigation mobile
//******************************************************************************
pwt_nav_mobile = {
    
    init: function () {     
        $("#mobile_nav").mmenu(
            { 
                extensions: ["pageshadow", "positioning", "fixedElements", "fullscreen"],
                offCanvas: { 
                    pageSelector: "#main_wrapper",
                    position: "right",
                    zposition: "front"
                },
            }
        );

        pwt_nav_mobile._init_events();
    },

    _init_events: function () {
        // Change hamburger button status
        var api = $('#mobile_nav').data('mmenu');

        api.bind('open:start', function () {
            $("button.hamburger").addClass("is-active");
            $(".navbar-header").addClass("mobile_menu_visible"); 
        });

        api.bind('close:finish', function () { 
            $("button.hamburger").removeClass("is-active");
            $(".navbar-header").removeClass("mobile_menu_visible"); 
        });

        var width = $( window ).width();

        // Close on resize
        $( window ).resize(function() {
            if ($( window ).width() !== width ) {
                pwt_nav_mobile.close();
                width = $( window ).width();
            }
        });
    },

    open: function () {
        $("#mobile_nav").data( "mmenu" ).open();        
    },

    close: function () {
        $("#mobile_nav").data( "mmenu" ).close();        
    },

    toggle: function () {
        if($('#mobile_nav').hasClass('mm-opened')) {
            this.close();
        }
        else {
            this.open();
        }
    }

};

})(jQuery); 