(function($) {



//******************************************************************************
//* Download
//******************************************************************************
pwt_download = {
    search_regx: [],
    $grids: [],

    init: function () {
        this._init_isotope();
        this._init_javascript_events();
    },

    _init_isotope: function () {

        // Init Isotope
        $('.download_container').each( function() {
            var current_id = $(this).data('searchId');

            pwt_download.$grids[current_id] = $('.download_container[data-search-id="' + current_id + '"] .grid').isotope({
                itemSelector: '.download',
                layoutMode: 'fitRows',
                filter: function() {
                    return pwt_download._get_filtered_item($(this), current_id);
                }
            });
        });


    },

    _init_javascript_events: function () {
        $('.download_search').each(function() {
            var current_id = $(this).parents('.download_container').data('searchId');

            $(this).keyup( pwt_download._debounce( function(search_id) {
                pwt_download.search_regx[search_id] = new RegExp( $('.download_container[data-search-id="' + search_id + '"] .download_search').val(), 'gi' );
                pwt_download.$grids[search_id].isotope();
            }, current_id, 200 ) );
        });

    },

    // Debounce so filtering doesn't happen every millisecond
    _debounce: function ( fn, fn_args, threshold ) {
        var timeout;
        return function debounced() {
            if ( timeout ) {
                clearTimeout( timeout );
            }
            function delayed() {
                fn(fn_args);
                timeout = null;
            }
            timeout = setTimeout( delayed, threshold || 100 );
        };
    },

    _get_filtered_item: function ($item, search_id) {
        if (pwt_download.search_regx[search_id]) {
            if ($item.text().match( pwt_download.search_regx[search_id] )) {
                $item.addClass('visible');
            } else {
                $item.removeClass('visible');
            }
        } else {
            $item.addClass('visible');
        }

        return '.visible';
    }

};

})(jQuery);