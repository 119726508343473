(function($) {

//******************************************************************************
//* Empty space
//******************************************************************************
pwt_empty_space = {
    
    init: function () {
        this._set_space_height_as_headline_height();

        $(window).on('nd_resize_ready', function() {
            pwt_empty_space._set_space_height_as_headline_height();
        });
    },

    _set_space_height_as_headline_height: function () {
        $('.pwt_empty_space[data-section="as_headline"]').each(function () {
            var $headline_element = $(this).closest('.vc_row').find('.wpb_content_element.headline [data-field="sub_headline"]');

            if ($headline_element.length) {
                // Set height as headline height on dektop and 
                // reset on mobile if mobile height is not selected 'as_desktop'
                if (window.innerWidth > SCREEN_SIZE_XS_MAX) {

                    // Get headline height from headline element in nearest row                    
                    $(this).children('div.d-none.d-md-block').height($headline_element.height() + 6);
                }
            }
        });
    }
};

})(jQuery); 