(function($) {

//******************************************************************************
//* Cut text to fit in container with fixed height
//******************************************************************************
nd_cut_text = {

    init: function () {

        // Set text content to fit in grid
        $(window).load(function() {
            nd_cut_text._prepare();
            nd_cut_text._fit_text();
            nd_same_height_groups.refresh();
        });

        $(window).on('nd_resize_ready', function() {
            nd_cut_text._fit_text();
            nd_same_height_groups.refresh();
        });
    },

    _prepare: function () {
        $("[data-action='cut_text_to_fit_height']").each(function () {
            
            // Put content in section "original"
            if($(this).find('[data-section="original"]').length === 0) {
                $(this).wrapInner('<div data-section="original" class="d-none"></div>');
            }

            // Add empty section "modified" if no "modified" section exist
            if($(this).find('[data-section="modified"]').length === 0) {
                $(this).prepend('<div data-section="modified"></div>');
            }
        });
    },

    _fit_text: function () {
        $("[data-action='cut_text_to_fit_height']").each(function () {

            var $orginal_elem = $(this).find('[data-section="original"]');
            var $modified_elem = $(this).find('[data-section="modified"]');

            // Remove class
            $(this).removeClass('visible'); 
            
            // Replace modified content with original content
            $modified_elem.html($orginal_elem.html());

            // Save referenece
            var container = $(this)[0];

            // Make an array of all child elements of modified 
            var $child_elements = [];
            $modified_elem.children().each(function () {
                $child_elements.push(this);
            });

            // Remove words and loop until it is not overflowing anymore
            if(container.offsetHeight < container.scrollHeight) {

                // Reverse the child array and loop through every element
                $($child_elements.reverse()).each(function () {
                    var max_loops = 100;

                    var text_content = $.trim($(this).html());

                    while (container.offsetHeight < container.scrollHeight) {
                        max_loops--;
                        
                        // Start removing word from the end
                        text_content = nd_cut_text._cut_text(text_content);

                        // Remove the container if it is empty
                        if(text_content === '') {
                            $(this).remove();
                        } else {

                            // Replace current child container with updated text_content
                            $(this).html(text_content + ' ...');
                        }

                        if (max_loops <= 0) {
                            break;
                        }
                    }
                });
            }

            // Add class 'visible' after cut text finished execution
            $(this).addClass('visible'); 
        });  
    },

    _cut_text: function(element_text) {

        // Use space or hyphen to separate, depending which one comes later in the string
        var last_index_of_space = element_text.lastIndexOf(" ");
        var last_index_of_hyphen = element_text.lastIndexOf("-");
        
        var last_index = (last_index_of_hyphen > last_index_of_space) ? last_index_of_hyphen : last_index_of_space;

        // Take content from the first position to the last index
        element_text = element_text.substring(0, last_index);

        // Return modified text content
        return element_text;
    }
};

})(jQuery); 