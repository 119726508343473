(function($) {


//******************************************************************************
//* Process Technology Grid element
//******************************************************************************
pwt_process_technology_grid = {
    $grid_wrapper: $('.process_technology_grid.grid .grid_wrapper'),
    $grid_filter: $('.process_technology_grid.grid .grid_filter'),

    first_load: true,

    element_filters : { 
        filter: 'all',
        default_filter: 'all'
    },

    init: function () {
        this._init_filter_preselect();
        this._init_isotope();
        this._init_javascript_events();
        
        // Set container width after vc row event
        $(window).on('nd_vc_row_ready', function() {
            pwt_process_technology_grid._re_render_isotope();
        });
    },

    _init_filter_preselect: function () {
        if(window.location.hash) {
            var hash = window.location.hash.substring(1);
            $active_filter = pwt_process_technology_grid.$grid_filter.find($('.filter_button[data-filter="'+ hash +'"]'));
            pwt_process_technology_grid._add_active_class_to_current_filter($active_filter);
        } else {
            $active_filter = pwt_process_technology_grid.$grid_filter.find($('.filter_button.active'));
        }

        if ($active_filter.length > 0) {
            pwt_process_technology_grid.element_filters.filter = $active_filter.attr('data-filter');

            // Replace state everytime when grid load for first time with the active filter
            window.history.replaceState({category: "" + pwt_process_technology_grid.element_filters.filter + ""}, '', '');
        }
    },

    _init_isotope: function () {
        // Init Isotope
        pwt_process_technology_grid.$grid_wrapper.isotope({
            itemSelector: '.grid .grid_item',
            layoutMode: 'fitRows',
            filter: pwt_process_technology_grid._get_filter(),
        });

        pwt_process_technology_grid.$grid_wrapper.isotope( 'reloadItems' ).isotope();
    },

    _init_javascript_events: function () {

        // On filter change
        pwt_process_technology_grid.$grid_filter.find('.filter_button').click(function (event) {
            pwt_process_technology_grid.element_filters.filter = $(this).attr('data-filter');
            pwt_process_technology_grid._add_active_class_to_current_filter(pwt_process_technology_grid.$grid_filter.find($('.filter_button[data-filter="' + pwt_process_technology_grid.element_filters.filter + '"]')));
            pwt_process_technology_grid._refresh_grid();

            // Push state when filter is changed
            window.history.pushState({category: "" + pwt_process_technology_grid.element_filters.filter + ""}, '', '#' + $(this).attr('data-filter'));
        });

        // On window pop event
        window.addEventListener('popstate', function(event) {
            pwt_process_technology_grid.element_filters.filter = event.state.category;
            pwt_process_technology_grid._add_active_class_to_current_filter(pwt_process_technology_grid.$grid_filter.find($('.filter_button[data-filter="' + pwt_process_technology_grid.element_filters.filter + '"]')));
            pwt_process_technology_grid._refresh_grid();
        }, false);

        $(window).on('nd_resize_ready', function() {
            pwt_process_technology_grid._refresh_grid();
        });        
    },

    _add_active_class_to_current_filter: function ($current_element) {
        pwt_process_technology_grid.$grid_filter.find($('.filter_button')).removeClass('active');

        // Set active class
        $current_element.addClass('active');
    },

    _refresh_grid: function () {
        pwt_process_technology_grid.$grid_wrapper.isotope( {filter: pwt_process_technology_grid._get_filter() });
    },

    _get_filter: function () {
        var filter_base =  '.process_technology_grid.grid .grid_wrapper .grid_item';
        var filter_selector = [];
        $(filter_base).removeClass('visible');

        pwt_process_technology_grid.$grid_wrapper.find($('.grid_item')).each(function(){
            category_list = $(this).attr('data-category-filter').split(',');

            if (category_list.indexOf(pwt_process_technology_grid.element_filters.filter) > -1) {
                var filter = filter_base.concat('[data-category-filter="', $(this).attr('data-category-filter'), '"]');
                if (filter_selector.indexOf(filter) < 0) {
                    filter_selector.push(filter);
                }
            }

        });

        // Current item selector depending on filter
        if (filter_selector.length > 0) {
            filter_selector = filter_selector.toString();
        }

        // Append class visible to the first n elements, whereas n is number of current visible elements
        $(filter_selector).each(function(){
            $(this).addClass('visible');
        });

        // Execute same height group to calculate all visible item height
        nd_same_height_groups.refresh();

        return '.visible';
    },

    // Re render isotope beacause isotope initialized and container width get set before vc finished it's container width calculation
    _re_render_isotope: function () {
        var container_width = $('.process_technology_grid.grid').width();
        pwt_process_technology_grid.$grid_wrapper.width(container_width);

        if (pwt_process_technology_grid.first_load) {
            pwt_process_technology_grid._refresh_grid();
            pwt_process_technology_grid.first_load = false;
        }
    }
};


})(jQuery);
