(function($) {


//******************************************************************************
//* Grid element
//******************************************************************************
pwt_article_grid = {
    $grid_wrapper: $('.article_grid.grid .grid_wrapper'),
    $grid_filter: $('.article_grid.grid .grid_filter'),

    MAX_VISIBLE_ELEMENTS: 6,
    first_load: true,

    element_filters : { 
        filter: 'all',
        default_filter: 'all',
        visible_elements: 6,
        possible_elements: 6
    },

    init: function () {
        this._init_filter_preselect();
        this._init_isotope();
        this._init_javascript_events();

        // Set container width after vc row event
        $(window).on('nd_vc_row_ready', function() {
            pwt_article_grid._re_render_isotope();
        });
    },

    _init_filter_preselect: function () {
        if(window.location.hash) {
            var hash = window.location.hash.substring(1);
            $active_filter = pwt_article_grid.$grid_filter.find($('.filter_button[data-filter="'+ hash +'"]'));
            pwt_article_grid._add_active_class_to_current_filter($active_filter);
        } else {
            $active_filter = pwt_article_grid.$grid_filter.find($('.filter_button.active'));
        }

        if ($active_filter.length > 0) {
            pwt_article_grid.element_filters.filter = $active_filter.attr('data-filter');

            // Replace state everytime when grid load for first time with the active filter
            window.history.replaceState({category: "" + pwt_article_grid.element_filters.filter + ""}, '', '');
        }
    },

    _init_isotope: function () {
        // Init Isotope
        pwt_article_grid.$grid_wrapper.isotope({
            itemSelector: '.grid .grid_item',
            layoutMode: 'fitRows',
            filter: pwt_article_grid._get_filter(),
        });
    },

    _init_javascript_events: function () {
        pwt_article_grid._refresh_more_button_visibility();

        // On filter change
        pwt_article_grid.$grid_filter.find('.filter_button').click(function (event) {
            pwt_article_grid.element_filters.filter = $(this).attr('data-filter');
            pwt_article_grid._add_active_class_to_current_filter(pwt_article_grid.$grid_filter.find($('.filter_button[data-filter="' + pwt_article_grid.element_filters.filter + '"]')));
            pwt_article_grid._refresh_grid();

            // Push state when filter is changed
            window.history.pushState({category: "" +pwt_article_grid.element_filters.filter +""}, '', '#' + $(this).attr('data-filter'));
        });

        // On window pop event
        window.addEventListener('popstate', function(event) {
            pwt_article_grid.element_filters.filter = event.state.category;
            pwt_article_grid._add_active_class_to_current_filter(pwt_article_grid.$grid_filter.find($('.filter_button[data-filter="' + pwt_article_grid.element_filters.filter + '"]')));
            pwt_article_grid._refresh_grid();
        }, false);

        // On 'show more' button click
        $('.show_more_button .button').on('click', function () {
            pwt_article_grid.element_filters.visible_elements += pwt_article_grid.MAX_VISIBLE_ELEMENTS;
            pwt_article_grid._refresh_grid();
        });

        $(window).on('nd_resize_ready', function() {
            pwt_article_grid._refresh_grid();
        });        
    },

    _add_active_class_to_current_filter: function ($current_element) {
        pwt_article_grid.$grid_filter.find($('.filter_button')).removeClass('active');

        // Reset number of shown elements
        pwt_article_grid.element_filters.visible_elements = pwt_article_grid.MAX_VISIBLE_ELEMENTS;
        $current_element.addClass('active');
    },

    _refresh_grid: function () {
        pwt_article_grid.$grid_wrapper.isotope( {filter: pwt_article_grid._get_filter() });
        pwt_article_grid._refresh_more_button_visibility();

        // Call cut text to fit text into teaser when items change in isotope
        nd_cut_text._fit_text();
    },

    _refresh_more_button_visibility: function() {
        $button = $('.show_more_button');
        var max_elems = $button.attr('data-max-elements');
        if (pwt_article_grid.element_filters.visible_elements < parseInt(max_elems) || pwt_article_grid.element_filters.visible_elements >= pwt_article_grid.element_filters.possible_elements) {
            $button.css('display','none');
        } else {
            $button.css('display','inline');
        }
    },

    _get_filter: function () {
        var filter_base =  '.article_grid.grid .grid_wrapper .grid_item';
        var filter_selector = [];
        $(filter_base).removeClass('visible no_border');

        pwt_article_grid.$grid_wrapper.find($('.grid_item')).each(function(){
            category_list = $(this).attr('data-category-filter').split(',');

            if (category_list.indexOf(pwt_article_grid.element_filters.filter) > -1) {
                var filter = filter_base.concat('[data-category-filter="', $(this).attr('data-category-filter'), '"]');
                if (filter_selector.indexOf(filter) < 0) {
                    filter_selector.push(filter);
                }
            }

        });

        // Current item selector depending on filter
        if (filter_selector.length > 0) {
            filter_selector = filter_selector.toString();
        }

        // Append class visible to the first n elements, whereas n is number of current visible elements
        var counter_added = 0;
        var counter_complete = 0;
        $(filter_selector).each(function(){
            if (counter_added < pwt_article_grid.element_filters.visible_elements) {
                $(this).addClass('visible');
                counter_added++;

                if (window.innerWidth > SCREEN_SIZE_XS_MAX) {
                    if (counter_added % 3 === 0) {
                        $(this).addClass('no_border');
                    }
                } else {
                    if (counter_added % 2 === 0) {
                        $(this).addClass('no_border');
                    }
                }
            }
            counter_complete++;
        });

        // Hide border in last child of current selected filter items
        $(filter_selector).last().addClass('no_border');

        // Possible elements counts how many events match the filter criteria
        pwt_article_grid.element_filters.possible_elements = counter_complete;

        return '.visible';
    },

    // Re render isotope beacause isotope initialized and container width get set before vc finished it's container width calculation
    _re_render_isotope: function () {
        var container_width = $('.article_grid.grid').width();
        pwt_article_grid.$grid_wrapper.width(container_width);
        
        if (pwt_article_grid.first_load) {
            pwt_article_grid._refresh_grid();
            pwt_article_grid.first_load = false;
        }
    }
};


//******************************************************************************
//* Carousel element
//******************************************************************************
pwt_article_carousel = {

    init: function () {

        $('.carousel .grid_wrapper').on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('.slick-active').last().addClass('no_border');
        });

        $('.carousel .grid_wrapper').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            $('.slick-active').removeClass('no_border');
        });

        $(document).ready(function(){
            $('.carousel .grid_wrapper').slick({
                speed: 1000,
                slidesToShow: 3,
                slidesToScroll: 3,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });

            $('.slick-active').last().addClass('no_border');
        });
    }

};


})(jQuery);
